import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import DicaExtra from "../components/DicaExtra"

import * as S from "../components/Pages/general"

const LegalPage = () => (
  <Layout>
    <SEO
      title="Aviso Legal"
      description="Aqui nessa página temos todos os termos legais e previsto em nossa legislação, caso tenha alguma dúvida que não seja respondida aqui entre em contato."
    />
    <S.GeneralWrapper>
      <S.GeneralTitle font>Aviso Legal</S.GeneralTitle>
      <S.GeneralText>
        Ao fazer qualquer indicação de cursos, produtos, sites, vídeos,
        palestrar ou conteúdos, fazemos todo esforço para garantir que estes
        representam a melhor forma e melhor caminho para um aprendizado rápido
        sem ser custoso.
      </S.GeneralText>
      <S.GeneralText>
        No entanto, o <S.GeneralLink to="/">Aprendendo Desenhar</S.GeneralLink>{" "}
        não garante que você irá obter quaisquer resultados utilizando nossas
        indicações, você concorda que o uso e resultados do curso é por sua
        conta.
      </S.GeneralText>
      <S.GeneralText>
        Ao adquirir um de nossos cursos, você aceita, concorda e compreende que
        você é totalmente responsável por seu progresso e resultados de sua
        participação e que nós não oferecemos nenhuma representação, garantia ou
        garantias verbalmente, ou por escrito sobre seus resultados de qualquer
        tipo.
      </S.GeneralText>
      <S.GeneralText>
        Você é o único responsável por seus resultados na vida e negócios que
        dependem de fatores pessoais, incluindo, mas não necessariamente
        limitado a, a sua habilidade, conhecimento, habilidade, dedicação,
        principalmente dedicação uma vez que desenhar se trata de uma habilidade
        comprovadamente treinável, desde que se tenha empenho.
      </S.GeneralText>
      <S.GeneralText>
        Você também entende que qualquer depoimento ou aprovações emitidas por
        nossos clientes, ou público representado em nossos programas, sites,
        conteúdo, páginas de destino, páginas de vendas ou ofertas não foram
        avaliadas cientificamente por nós e os resultados experimentados pelos
        indivíduos podem variar significativamente.
      </S.GeneralText>
      <S.GeneralText>
        Quaisquer declarações descritas no nosso websites, programas, conteúdos
        e ofertas são simplesmente nossa opinião, portanto, não são garantias ou
        promessas de desempenho real. Nós não oferecemos assessoria jurídica,
        médica, psicológica ou financeira profissional.
      </S.GeneralText>
    </S.GeneralWrapper>
    <DicaExtra />
  </Layout>
)

export default LegalPage
